.lights {
    position: relative;
    .line {
      width: 100%;
      height: 1px;
      left: 0;
      position: absolute;
      opacity: 0.5;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #e0d9ff 0%,
        rgba(224, 217, 255, 0) 100%
      );
      filter: blur(0.5px);
    }
  }
  