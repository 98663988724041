#home {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-image: url("https://cdn.discordapp.com/attachments/1040027129141805126/1084152158028574880/milad-fakurian-ICTjWYzpoc0-unsplash.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .profile {
    width: 130px;
    height: 130px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      position: relative;
      z-index: 1;
      margin-top: 20px;
    }
  }
  .profile_text {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;

    .sphere {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    h3 {
      color: #fff;
      font-size: 2rem;
      @media screen and (max-width: 500px) {
        font-size: 1.5rem;
      }
      span {
        color: #7fdbff;
      }
    }
    .job {
      color: #fff;
      opacity: 0.5;
      font-size: 0.85rem;
    }
    .text {
      margin-top: 2rem;
      color: #fff;
      text-align: center;
      font-size: 4.2rem;
      line-height: 1.3;

      @media screen and (max-width: 930px) {
        font-size: 3.5rem;
        margin-top: 2rem;
      }
      @media screen and (max-width: 770px) {
        font-size: 2.5rem;
      }
      @media screen and (max-width: 550px) {
        font-size: 2rem;
      }
    }
    a {
      margin-top: 3rem;
      text-decoration: none;
      color: #000;
      background: #fff;
      padding: 0.65rem 1rem;
      border-radius: 10px;
      font-size: 0.85rem;
      text-transform: capitalize;
    }
    section {
      position: absolute;
      background: rgba(255, 255, 255, 0.15);
      color: #fff;
      padding: 0.35rem 1rem;
      border-radius: 25px;
      font-size: 0.85rem;
      cursor: pointer;
    }
    @media screen and (max-width: 800px) {
      section {
        display: none;
      }
    }
    .fullstack {
      right: -1rem;
      top: 3.5rem;
    }
    .ui {
      top: 2rem;
      left: 2rem;
    }
    .mecengineer {
      bottom: 2rem;
      left: 0;
    }
    .freelancer {
      bottom: 2rem;
      right: -1rem;
    }
  }
}
